import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="Home" />
    <div className="max-w-5xl mx-auto mt-10 mb-20 prose-lg md:text-center">
      <p>
        This year, Robins & Morton partnered with{" "}
        <a
          class="font-semibold"
          href="https://studiobythetracks.org/"
          rel="noopener noreferrer"
        >
          Studio By The Tracks
        </a>
        , a studio and gallery for adult artists on the autism spectrum, to
        depict events and projects that shaped our team members across the
        country this year and deeply impacted the communities in which we live
        and work.
      </p>
      <p>
        No matter if we were opening a new office, a new hospital or celebrating
        a milestone together, each of these moments came together to make 2022
        remarkable for our teams across the country. To learn more about these
        moments and the artist that brought it to life, please browse through
        our virtual timeline below.
      </p>
    </div>
    <div className="grid grid-cols-2 gap-10 md:grid-cols-3">
      {data.posts.nodes.map(({ frontmatter, fields }) => (
        <Link to={fields.slug} key={fields.slug}>
          <GatsbyImage
            image={frontmatter.featuredImage.square.gatsbyImageData}
            alt={frontmatter.title}
            className="mb-2"
          />
          <h2 className="inline font-semibold tracking-wide uppercase">
            {frontmatter.title}{" "}
          </h2>
          <span className="pl-4 font-thin normal-case">
            {frontmatter.eventDate}
          </span>
        </Link>
      ))}
    </div>
  </Layout>
)

export const query = graphql`
  query SITE_INDEX_QUERY {
    site {
      ...SiteMetadata
    }
    posts: allMdx(
      filter: { fields: { slug: { regex: "/posts/" } } }
      sort: { fields: [frontmatter___orderBy], order: ASC }
    ) {
      nodes {
        ...PostData
      }
    }
  }
`

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Happy Holidays" />

export default IndexPage
